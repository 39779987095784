import { MapContainer, TileLayer, LayersControl, Pane, Polyline } from 'react-leaflet';
import config from '../config.json'
import { fetchJSON, useJSON } from '../data/fetch';
import './map.css';
import { useState, useEffect } from 'react';

let mapOptions = {};

export function Map({ children, layers, style, ...props }) {
  let [attr, setAttr] = useState(mapOptions);
  let [roads, setRoads] = useState(false);
  useEffect(() => {
    if (mapOptions && !mapOptions.attribution) {
      fetchJSON('GET', '/map/options').then(a => {
        mapOptions = a;
        setAttr(mapOptions);
      });
    }
  }, [setAttr]);

  let defaults = config.map || {};
  if(!attr) return 'Nincs térkép szolgáltatás';
  let l = attr.layers;
  if (l && !l.roads) roads = false;

  return <MapContainer
    style={{ height: '100%', width: '100%', ...style }}
    boxZoom={false}
    scrollWheelZoom={true}
    doubleClickZoom={true}
    minZoom={4}
    {...defaults}
    {...props}
  >
    <LayersControl collapsed={true}>
      <LayersControl.BaseLayer checked name="Alap">
        <TileLayer
          attribution={attr && attr.attribution}
          url="/json/map/tile/raster/{z}/{x}/{y}"
        />
      </LayersControl.BaseLayer>
      {l && l.satellite && <LayersControl.BaseLayer name="Műhold">
        <TileLayer
          attribution={attr && attr.attribution}
          url="/json/map/tile/satellite/{z}/{x}/{y}"
          eventHandlers={{
            add: (e) => {
              setRoads(true);
            },
            remove: (e) => {
              setRoads(false);
            }
          }}
        />
      </LayersControl.BaseLayer>}
      {l && l.traffic && <LayersControl.Overlay name="Forgalom" >
        <Pane name="traffic" style={{ zIndex: 402 }}><TileLayer
          url="/json/map/tile/traffic/{z}/{x}/{y}"
        /></Pane>
      </LayersControl.Overlay>}
      {l && l.restrictions && <LayersControl.Overlay name="Korlátozások" >
        <Pane name="restr" style={{ zIndex: 403 }}><TileLayer
          url="/json/map/tile/restrictions/{z}/{x}/{y}"
        /></Pane>
      </LayersControl.Overlay>}
      {roads && <LayersControl.Overlay name="Útháló" checked >
        <Pane name="roads" style={{ zIndex: 401 }}><TileLayer
          url="/json/map/tile/roads/{z}/{x}/{y}"
        /></Pane>
      </LayersControl.Overlay>}
      {layers}
      <Pane name="labels" style={{ zIndex: 501 }}><TileLayer
        url="/json/map/tile/labels/{z}/{x}/{y}"
      /></Pane>
    </LayersControl>
    {children}
  </MapContainer>
}

export function RoutePolyline({ routeId, alternative, selected, pathOptions, ...other }) {
  let json = useJSON({ url: 'route', id: routeId });
  if (!json || !json.polyline) return null;
//  console.log("RoutePolyline",routeId,json);
  return <Polyline
    positions={json.polyline}
    pathOptions={{
      color: '#00c',
      opacity: alternative ? 0.7 : 1,
      weight: selected ? 5 : 2,
      zIndex: 1,
      ...pathOptions,
      ...other
    }} />
}

export default Map;