import { AppBar, Card, CardContent, Grid, IconButton, Input, LinearProgress, Table, TableBody, TableCell, TableRow, TextField, Toolbar, Button, Typography, Tab, Tabs, Link, Chip } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { Protected, useAuth } from '../data/auth';
import { FetchContext, useRefresh, useJSON, fetchJSON } from '../data/fetch';
import { Clear, Logout, Refresh, Search, Beenhere, Build, HelpOutline, Delete, AttachFile } from '@mui/icons-material';
import { debounce, findKey } from 'lodash';
import { moneyToStr, calcBounds } from '../../utils';
import { Map } from '../components/Map.js';
import { blue, red, green } from '@mui/material/colors';
import { Polyline } from 'react-leaflet';
import { useDropzone } from 'react-dropzone'

function KocsiCard({ data: { kocsi, forg }, setKocsi }) {
  return <Card sx={{ m: 1 }}>
    <CardContent>
      <Typography variant="h6">{kocsi}</Typography>
      <Grid container divider>
        {forg.map(f => <Grid key={f.idoszak} item xs={4} onClick={() => setKocsi({ kocsi, idoszak: f.idoszak })} style={{ cursor: 'pointer' }}>
          <Typography variant="caption">{f.idoszak}</Typography>
          <Typography variant="body2">{f.cnt} megbízás </Typography>
        </Grid>)}
      </Grid>
    </CardContent>
  </Card>
}


function JaratCard({ kocsi, jarat, viszonylat, kezdet, veg, vkm, ukm, vear, uear, onClick, charterstat }) {
  let staticon = <HelpOutline sx={{ color: blue[500] }} />;
  if (charterstat == 1) {
    staticon = <Beenhere sx={{ color: green[500] }} />
  }
  if (charterstat == 2) {
    staticon = <Build sx={{ color: red[500] }} />
  }
  return <Card onClick={onClick} sx={{ m: 1 }}>
    <CardContent style={{ cursor: 'pointer' }} onClick={onClick}>
      <Grid container divider>
        <Grid key='icon' item xs={2}>{staticon}</Grid>
        <Grid key='title' item xs={10}><Typography variant="subtitle2">{kocsi} - {jarat}</Typography></Grid>
      </Grid>
      <Grid container divider>
        <Grid key={1} item xs={5}><Typography variant="body2">{viszonylat}</Typography></Grid>
        <Grid key={2} item xs={4}><Typography variant="body2">{kezdet}</Typography></Grid>
        <Grid key={3} item xs={3}><Typography variant="body2">{vkm + ukm} Km</Typography></Grid>
      </Grid>
    </CardContent>
  </Card>
}


function KocsiList({ setKocsi, search }) {
  let list = useJSON({ url: 'alvall/kocsi' });
  if (!list) return <LinearProgress />
  if (search) {
    list = list.filter(l => {
      if (l.kocsi.includes(search)) return true;
      return false;
    });
  }

  return <div style={{ minWidth: 350, height: '100%', overflowY: 'scroll' }}>
    {list.map((k) => <KocsiCard key={k.kocsi} setKocsi={setKocsi} data={k} />)}
  </div>
}

function Searchbar({ setSearch }) {
  const {logout} = useAuth();
  const refresh = useRefresh();
  const search = debounce(setSearch, 500);
  const keyUp = (ev) => {
    console.log("keyup", ev);
    search(ev.target.value);
  }

  return <AppBar>
    <Toolbar variant="dense" style={{ justifyContent: 'space-between' }}>
      <IconButton onClick={refresh}><Refresh /></IconButton>
      <Input style={{ flexGrow: 100 }} type="text" startAdornment={<Search />} endAdornment={<Clear />} onKeyUp={keyUp} />
      <IconButton onClick={logout}><Logout /></IconButton>
    </Toolbar>
  </AppBar>
}

function JaratList({ kocsi, setJarat, search }) {
  let list = 'Válaszon egy rendszámot és időszakot';
  if (kocsi) list = <IdoszakList kocsi={kocsi.kocsi} idoszak={kocsi.idoszak} search={search} setJarat={setJarat} />
  return <div style={{ minWidth: 350, height: '100%', overflowY: 'scroll' }}>
    {list}
  </div>
}

function IdoszakList({ kocsi, idoszak, search, setJarat }) {
  let list = useJSON({ url: `alvall/jarat/${kocsi}/${idoszak}` });
  if (!list) return <LinearProgress />
  if (search) {
    list = list.filter(l => {
      if (l.jarat.includes(search)) return true;
      return false;
    });
  }

  list = list.map(j => <JaratCard key={j.jarat} {...j} onClick={() => setJarat({ pos: j.jarat, kocsi: j.kocsi })} />);
  return list
}


function Alvelsz({ fej, tetel }) {
  //  let opt = [];
  let opt = tetel.map(t => { return { title: `Útdij ${t.orszag}`, rdij: moneyToStr(t.rdij, '€'), udij: moneyToStr(t.udij, '€') } });
  if (fej.allas > 0 || fej.uallas > 0) {
    opt.push({ title: 'Állásdíj', rdij: moneyToStr(fej.allas, '€'), udij: moneyToStr(fej.uallas, '€') });
  }
  if (fej.parkolas > 0 || fej.uparkolas > 0) {
    opt.push({ title: 'Parkolás', rdij: moneyToStr(fej.parkolas, '€'), udij: moneyToStr(fej.uparkolas, '€') });
  }
  if (fej.egyeb > 0 || fej.uegyeb > 0) {
    opt.push({ title: 'Egyéb', rdij: moneyToStr(fej.egyeb, '€'), udij: moneyToStr(fej.uegyeb, '€') });
  }
  if (fej.eloft > 0) {
    opt.push({ title: 'Előrakó', rdij: moneyToStr(fej.elouto, "db"), udij: moneyToStr(fej.eloft, 'HUF') });
  }
  let status = fej.charterstat === 2 ? 'Reklamáció alatt' : (fej.charterstat === 1 ? 'Elfogadva' : '');
  let f = <Card>
    <Table size="small">
      <TableBody>
        <TableRow><TableCell>Járat</TableCell><TableCell>{fej.jarat}</TableCell><TableCell>{fej.kocsi}</TableCell></TableRow>
        <TableRow><TableCell>Viszonylat</TableCell><TableCell>{fej.viszonylat}</TableCell><TableCell>{fej.kezdet}</TableCell></TableRow>
        <TableRow><TableCell>Felelős</TableCell><TableCell>{fej.user}</TableCell><TableCell>{status}</TableCell></TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align='right'><i>Rakott</i></TableCell>
          <TableCell align='right'><i>Üres</i></TableCell>
        </TableRow>
        <TableRow><TableCell>Megtett km</TableCell>
          <TableCell align='right'>{fej.vkm} Km * {moneyToStr(fej.vear, '€')}</TableCell>
          <TableCell align='right'>{fej.ukm} Km * {moneyToStr(fej.uear, '€')}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Km díj</TableCell>
          <TableCell align='right'>{moneyToStr(fej.vkm * fej.vear, '€')}</TableCell>
          <TableCell align='right'>{moneyToStr(fej.ukm * fej.uear, '€')}</TableCell>
        </TableRow>
        {opt.map(t => <TableRow key={t.title}>
          <TableCell>{t.title}</TableCell>
          <TableCell align='right'>{t.rdij}</TableCell>
          <TableCell align='right'>{t.udij}</TableCell>
        </TableRow>)}

        <TableRow>
          <TableCell><b>Összes díj</b></TableCell>
          <TableCell align='right'><b>{moneyToStr(fej.sum, '€')}</b></TableCell>
          <TableCell align='right'><b>{moneyToStr(fej.eloft, 'HUF')}</b></TableCell>
        </TableRow>

      </TableBody>
    </Table>
  </Card>
  return f;
}

function Tetel({ tetel }) {
  return 'tetel';
}

function MsgCard({ irany, stamp, msg }) {
  //irany == 1
  let cardstyle = irany === 1 ? { m: 1, p: 1, ml: 10 } : { m: 1, p: 1, mr: 10 }
  return <div>
    <Typography component="div" variant="caption" style={{ textAlign: irany === 2 ? 'left' : 'right', marginBottom: -10 }}> {stamp} </Typography>
    <Card sx={cardstyle}>
      <Typography variant="body2">{msg}</Typography>
    </Card>
  </div>
}

function KnaploList({ list }) {
  console.log(list);
  return list.map(x => <MsgCard key={x.sorszam} {...x} />);
}

function ACard({ irany, stamp, memo }) {
  return <Card sx={{ m: 1 }}>
    <CardContent>
      <Typography variant="subtitle2"> {irany == 2 ? "Üzenet" : "Válasz"} {stamp} </Typography>
      <Grid container divider>
        <Grid><Typography variant="body2">{memo}</Typography></Grid>
      </Grid>
    </CardContent>
  </Card>
}


function MsgBox({ fej }) {
  const [message, setMessage] = useState('');

  function sendStatus({ fej, message, status }) {
    if (fej && fej.sorszam) {
      console.log('postmessage');
      fetchJSON('POST', 'alvall/setstatus', { sorszam: fej.sorszam, status, message: message || '' });
      document.body.dispatchEvent(new CustomEvent('CLEAR_CACHE', { detail: [] }));
    };
  }

  let send = () => {
    sendStatus({ fej, message, status: 2 });
    setMessage('');
  }
  let ok = () => {
    sendStatus({ fej, message, status: 1 });
    setMessage('');
  }

  let change = (event) => {
    setMessage(event.target.value);
  }

  return <div>
    <Grid container>
      <Grid item xs={12}><TextField fullWidth required multiline id='msg' value={message} placeholder='Kifogásait és észrevételeit az elszámolásal kapcsolatban itt adhatja meg' rows='3' variant='standard' onChange={change} /></Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={6} align='left' onClick={send}><Button variant='outlined' color='error'>Elküldöm a fenti üzenetet</Button></Grid>
      <Grid item xs={6} align='right' onClick={ok}><Button variant='contained' color='success'>Elfogadom az elszámolást</Button></Grid>
    </Grid>
  </div>;

};


function Anaplo({ fej, naplo }) {
  console.log('naplo', naplo);
  let msglist = naplo.map(x => <ACard key={x.sorszam} {...x} />);
  let msgbox = '';
  if (fej.charterstat !== 1) msgbox = <MsgBox fej={fej} />
  return <Card>
    <CardContent>
      <div style={{ marginTop: '5px' }}>
        {msglist}
      </div>
      {msgbox}
    </CardContent>
  </Card>

};

function CsatolmanyUpload({ fej, csatolmany }) {
  const refresh = useRefresh();

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    console.log("acceptedFiles", acceptedFiles);
    let p = acceptedFiles.map(f => {
      let formData = new FormData();
      formData.set('file', f);
      formData.set('idoszak', fej.idoszak);
      formData.set('sorszam', fej.sorszam);
      return fetch('/json/alvall/csatolmany', {
        method: 'POST',
        body: formData
      })
    });
    Promise.all(p).then(refresh);
  }, [refresh, fej])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const delCsatol = x => () => fetchJSON('POST', 'alvall/csatolmany/delete', { sorszam: fej.sorszam, idoszak: fej.idoszak, filename: x.name }).then(refresh());
  let uploadlist = csatolmany.map(x => <Chip icon={<AttachFile />} variant="outlined" onDelete={delCsatol(x)} onClick={ev =>{ev.stopPropagation(); window.open(x.url, 'kepek')}} key={x.url} label={x.name} />);
  return <Card {...getRootProps()}>
    <input {...getInputProps()} />
    <CardContent style={{ background: isDragActive ? '#9f9' : 'inherit' }}>
      <div style={{ marginTop: '5px' }}>
      <Typography variant="h6" align="center" style={{ color: '#ccc', marginBottom: 6 }}>Húzza ide a csatolmányokat</Typography>
        {uploadlist}
      </div>
    </CardContent>
  </Card>

};


function Csatolmany({ fej, csatolmany }) {
  let uploadlist = csatolmany.map(x => <Chip icon={<AttachFile />} variant="outlined" onClick={() => window.open(x.url, 'kepek')} key={x.url} label={x.name} />);
  return <Card>
    <CardContent>
      <div style={{ marginTop: '5px' }}>
        {uploadlist}
      </div>
    </CardContent>
  </Card>
};

function Total({ value }) {
  return <Card>
    <CardContent>
      <div style={{ marginTop: '5px' }}>
        <Typography variant="h6" align="center" style={{ color: '#090', marginBottom: 0}}>Számlázásra váró összeg: &nbsp; {moneyToStr(value, '€')}</Typography>
      </div>
    </CardContent>
  </Card>
};

function JaratInfo({ jarat }) {
  let [tab, setTab] = useState(0);
  const [map, setMap] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  let info = useJSON({ url: `alvall/jarat`, method: 'POST', pos: jarat ? jarat.pos : '', kocsi: jarat ? jarat.kocsi : '' });

  useEffect(() => {
    if (info) {
      let { gps } = info;
      if (map && gps) {
        let all = [...gps.szakasz, ...gps.ures];
        let bounds = calcBounds(all);
        if (bounds && bounds[0] && bounds[1]) {
          map.fitBounds(bounds, { padding: [30, 30], maxZoom: 14 });
        }
      }
    }
  }, [info, map]);


  let content = null;
  if (!info) {
    content = <LinearProgress />;
  } else {
    if (!info.tetel) {
      content = 'Válasszon egy járatot';
    } else {
      content = <>
        {//<div style={{whiteSpace: 'pre-wrap', overflow: 'auto', height: 200, maxWidth:400}}>{JSON.stringify(info, null, 2)}</div>

        }
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={5} style={{ height: '100%' }}>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

            <div style={{ paddingBottom: 4 }}><Total value={info.total}/></div>
            <div style={{ paddingBottom: 4 }}><Alvelsz fej={info.alvelsz} tetel={info.atetel} /></div>
              <div style={{ flexGrow: 10, overflow: 'auto', paddingTop: 4 }}>
                <Anaplo fej={info.alvelsz} naplo={info.anaplo} />
              </div>
              <div style={{ paddingTop: 4 }}>
                {info.alvelsz.charterstat === 1? <Csatolmany fej={info.alvelsz} csatolmany={info.csatolmany} /> : <CsatolmanyUpload fej={info.alvelsz} csatolmany={info.csatolmany} />}
              </div>
            </div>
          </Grid>
          <Grid item xs={7} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Tabs value={tab} onChange={handleTabChange}><Tab label='Térkép' /><Tab label='Sofőr kommunikáció' /></Tabs>
            <div style={{ flexGrow: 10, overflow: 'auto' }}>
              {tab === 0 && <Map whenCreated={setMap}>
                {info && info.gps && info.gps.szakasz && <Polyline positions={info.gps.szakasz} pathOptions={{ color: '#0c0', opacity: 1, weight: 5, zIndex: 10 }} />}
                {info && info.gps && info.gps.ures && <Polyline positions={info.gps.ures} pathOptions={{ color: '#00c', opacity: 1, weight: 5, zIndex: 1 }} />}
              </Map>}
              {tab === 1 && <KnaploList list={info.knaplo} />}
            </div>
          </Grid>
        </Grid>
      </>
    }
  }

  return <div style={{ height: '100%', background: '#eee', flexGrow: 100 }}>
    {content}
  </div>
}


function AlvallPage() {
  const toolbar = 40;
  const [kocsi, setKocsi] = useState();
  const [jarat, setJarat] = useState();
  const [search, setSearch] = useState('');

  return (
    <Protected title="Alvállalkozói elszámolás">
      <FetchContext>
        <div style={{ position: 'absolute', top: 0, height: toolbar, left: 0, right: 0, padding: 16, background: '#fff', display: 'flex', flexWrap: 'wrap', alignItems: 'stretch' }}>
          <Searchbar setSearch={setSearch} />
        </div>
        <div style={{ position: 'absolute', top: toolbar, bottom: 0, left: 0, right: 0, padding: 16, background: '#fff', display: 'flex', background: '#eee' }}>
          <KocsiList setKocsi={setKocsi} search={search} />
          <JaratList kocsi={kocsi} setJarat={setJarat} search={search} />
          <JaratInfo jarat={jarat} />
        </div>
      </FetchContext>
    </Protected>
  );
}




export default AlvallPage;