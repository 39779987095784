
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'
import '../../../node_modules/typeface-roboto-multilang/latin-ext.css';
import config from '../config.json';

import { WindowDimensionsProvider } from '../components/Responsive';
import { AuthProvider } from '../data/auth';
import { SnackbarProvider } from 'notistack';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AlvallPage from './AlvallPage';
import { FieldArray, useFormikContext, Formik } from 'formik';

import './global.css';

const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'div',
          body2: 'div',
          inherit: 'div'
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 16px 16px'
        }
      }
    }
  },
  palette: {
    primary: { main: config.theme && config.theme.primary ? config.theme.primary : undefined },//Gönczi
    secondary: { main: config.theme && config.theme.secondary ? config.theme.secondary : undefined },//Gönczi
    background: {
      default: '#eee',
    }
  }
});

export function AppContext({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <AuthProvider>
          <WindowDimensionsProvider>
            {children}
          </WindowDimensionsProvider>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

function App() {
  console.log("APP");
  return (
    <AppContext>
      <Router>
        <Switch>
          <Route exact path="/">
            <AlvallPage />
          </Route>
        </Switch>
      </Router>
    </AppContext>
  );
}

export default App;