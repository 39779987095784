import React, { useState, createContext, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import { FastField } from 'formik';
import { Card, CardContent, Button, TextField, Box, Typography, Container, Avatar } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { fetchJSON, setAuthFailed } from './fetch';
import { setCookie, removeCookie } from './cookie';
import { useSnackbar } from 'notistack';
import {theme} from '../config.json';

const AuthCtx = createContext();

export function AuthProvider({ children }) {
  let cred = localStorage.getItem('cred');
  if (cred) {
    cred = JSON.parse(cred);
  } else {
    cred = { token: false, user: false };
  }
  const [user, setUser] = useState(cred);
  const [module, setModule] = useState(false);
  function login(payload, module) {
    localStorage.setItem('cred', JSON.stringify(payload));
    setCookie('token', payload.token, 2);
    setUser(payload);
    setModule(module);
  }

  function logout() {
    setUser(false);
    localStorage.setItem('cred', '');
    removeCookie('token');
  }
  setAuthFailed(logout);

  return <AuthCtx.Provider value={{ user, login, logout, module, setModule }} > {children}</ AuthCtx.Provider>
}


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      Fontis Software Kft.
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Login({ login, background, title, module }) {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (value) => {
    let {token,user} = await fetchJSON('POST', '/login', value);
    if (token) {
      login({ user, token }, module);
    } else {
      enqueueSnackbar('Érvénytelen felhasználónév vagy jelszó', { variant: 'error' });
    }
  };

  return <div style={{
    background: '#ccc',
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }}>
    <Container component="main" maxWidth="xs">


      <Card >
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
          <CardContent>
            <Formik initialValues={{ login: '', psw: '' }} onSubmit={onSubmit}>
              {props => (
                <Box component="form" onSubmit={props.handleSubmit} noValidate sx={{ mt: 1 }}>
                  <FastField name="login">{({ field }) => <TextField fullWidth required margin="normal" autoFocus label="Felhasználói név" {...field} />}</FastField>
                  <FastField name="psw">{({ field }) => <TextField fullWidth required margin="normal" type="password" label="Jelszó" {...field} />}</FastField>
                  <div style={{ textAlign: 'right' }}>
                    <Button variant="contained" type="submit" fullWidth sx={{ mt: 3, mb: 2 }}>Belépés</Button>
                  </div>
                </Box>
              )}
            </Formik>
          </CardContent>
        </Box>
      </Card>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  </div>
}

export function useAuth() {
  return useContext(AuthCtx);
}

export function Protected({ privilege, children, background, title, module }) {
  const { user, login, module: m, setModule } = useAuth();
  if(!background && theme) background = theme.login;
  useEffect(() => {
    if (m !== module) {
      setModule(module);
    }
  }, [m, setModule, module]);

  if (user && user.user) {
    //ide jöhet a szükséges privilegium ellenőrzése
    return children;
  } else {
    return <Login login={login} background={background || '/login.jpg'} title={title || 'Bejelentkezés'} module={module} />
  }

}
