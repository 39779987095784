import React, { useCallback, useEffect, useState, useContext, createContext, useMemo } from 'react';
import { buildUrl, now, stampFromMoment, stampToMoment } from '../../utils'
import moment from 'moment'


var prefix = '/json/';
export const setPrefix = p => prefix = p;

export function createRequest(method, url, body) {
  let q = {};
  if (method === 'GET' && body) {
    q = Object.assign(q, body);
    body = false;
  }
  if (method === 'POST') {
    if (!body) body = {};
  }

  url = buildUrl(url, q);
  while (url.substr(0, 1) === '/') {
    url = url.substr(1);
  }

  url = prefix + url;
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': "no-cache, no-store, must-revalidate",
    'Pragma': "no-cache",
    'Expires': 0
  };

  console.log("createRequest",url,body);

  return new Request(url, {
    method: method || 'POST',
    headers,
    body: body ? JSON.stringify(body) : null
  });
}

let failed = null;
export function setAuthFailed(func) {
  failed = func;
}

function error(e) {
  console.log("ERROR", e);
/*  let errors = localStorage.getItem('NetworkErrors');
  if (errors) errors = JSON.parse(errors);
  if (!errors) errors = [];
  e.stamp = now();
  errors.push(e);
  localStorage.setItem('NetworkErrors', JSON.stringify(errors));*/
}

const retry = {};

export async function fetchJSON(method, url, body = {}) {
  try {
    //    console.log("fetchJSON", {url,method});
    const req = createRequest(method, url, body);
    const response = await fetch(req);
    if (response.ok) {
      const ret = await response.json();
      return ret;
    } else if (response.status === 401) {
      if (failed) failed();
      console.log("failed response", response);
//      error({ error: 'Failed request', method, url });
    }else{
      throw new Error(response.status+' '+response.error);
    }
    
  } catch (e) {
    console.error("NetworkError", e);
    error({ error: 'fetch error ' + e, method, url });
  }
}


export async function postCommand(url, command, payload) {
  if (!command) alert('Missing Command');
  return await fetchJSON('POST', '/' + url + '/command', { command, payload });
}


const FetchCtx = createContext();
export const useRefresh = () => useContext(FetchCtx).refresh;

const cache = {};

export const FetchContext = ({ children }) => {
  const [nemerdekes, setCache] = useState([]);;
 // console.log("FetchContext");
  const updateUI = () => setCache({})

  const refresh = useCallback((tables) => {
    //    console.group("refresh "+tables);
    if (!tables || typeof tables === 'object') {
      Object.keys(cache).forEach(k => delete cache[k]);
      updateUI();
    } else {
      if (!Array.isArray(tables)) tables = [tables];
      tables.map(t => {
        Object.keys(cache).filter(k => k.includes(t)).map(k => {
          delete cache[k];
        })
      })
      updateUI()
    }
  }, [nemerdekes, setCache]);

  const value = useMemo(() => ({
    refresh,
    cache: [cache, updateUI],
  }), [refresh]);

  useEffect(() => {
    const listener = (e) => {
      //     console.log("refresh",e);
      refresh(e.detail);
    }
    document.body.addEventListener('CLEAR_CACHE', listener);
    return () => document.body.removeEventListener('CLEAR_CACHE', listener);
  }, [refresh])
  //  console.log("FetchContext",Object.keys(cache));

  return <FetchCtx.Provider value={value}>{children}</FetchCtx.Provider>
}



export const useJSON = ({ url, id, key, method, wait, localStore, ...body }) => {
  const ctx = useContext(FetchCtx);
  let [cache, updateUI] = ctx.cache;
  key = key || JSON.stringify({ url, id, body });
  if (url.includes('options/')) localStore = true;

  let cv = cache[key];
  if (cv) cv = cv.value;
  if (!cv && localStore) {
    const st = localStorage.getItem(key);
    if (st) cv = JSON.parse(st);
  }

  const [data, setData] = useState(cv);

  const getData = async () => {
    if (wait) return;
    //    console.log("getData",key);

    let cv = cache[key];
    if (cv) {
      if (!cv.pending) {
        //        console.log("megjött az adat",key);
        setData(cv.value);
      } else {
        //        console.log("várunk az adatra",key);
      }
    } else {
      try {
        cache[key] = { value: null, pending: now() };
        let data = await fetchJSON(method || 'GET', id ? `${url}/${id}` : url, body);
        if (typeof data === 'undefined') {
          error({ error: 'undefined adat', method, url, id, body, key });
          retry[key] = moment().add(20, 's');
        } else {
          cache[key] = { value: data };
          delete retry[key];
          if (localStore) localStorage.setItem(key, JSON.stringify(data));
          updateUI();
        }
      } catch (e) {
        console.log("Fetch error:", e);
      }
    }
  }

  useEffect(() => { getData(); }, [key, cache, updateUI, localStore, wait]);

  return data;
}


setInterval(async () => {
  try {
    let errors = localStorage.getItem('NetworkErrors');
    if (errors) {
      errors = JSON.parse(errors);
      const req = createRequest('POST', 'networkerror', errors);
      const res = await fetch(req);
      if (res && res.status === 200) {
        localStorage.removeItem('NetworkErrors');
        Object.keys(retry).forEach((k) => {
          document.body.dispatchEvent(new CustomEvent('CLEAR_CACHE', { detail: k }));
        })
      }
    }
    Object.entries(cache).forEach(([key, v]) => {
      if (!v.logged && v.pending < stampFromMoment(moment().subtract(20, 's'))) {
        error({ error: "Pending request", started: v.pending, key });
        console.log("Pending request");
        v.logged = now();
      }
    })
  } catch (e) {
    console.log("report Error", e);
  }

}, 10000);